import { axiosInstance } from '@/plugins/axios'

export class PromoCodeResource {
  constructor (axios) {
    this.axios = axios
  }

  list (options = {}) {
    const routeUri = '/v1/promo-codes?filter[owner_type]=admin'
    return this.axios.get(routeUri, options)
  }

  listReferralCodes (options = {}) {
    const routeUri = '/v1/promo-codes?filter[owner_type]=customer'
    return this.axios.get(routeUri, options)
  }

  listUsages (promotionId, options = {}) {
    const routeUri = `/v1/promo-codes/${promotionId}/usages`
    return this.axios.get(routeUri, options)
  }

  show (promotionId, options = {}) {
    const routeUri = `/v1/promo-codes/${promotionId}`
    return this.axios.get(routeUri, options)
  }

  endPromotionById (promotionId, options = {}) {
    const routeUri = `/v1/promo-codes/${promotionId}`
    return this.axios.delete(routeUri, options)
  }

  createPromotion (payload, options = {}) {
    return this.axios.post('/v1/promo-codes', payload, options)
  }
}

const defaultPromoResource = new PromoCodeResource(axiosInstance)

export default defaultPromoResource
