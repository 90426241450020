var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card m-4"},[_c('div',{staticClass:"card-body p-0"},[_c('v-server-table',{ref:"companiesTable",attrs:{"columns":_setup.columns,"options":_setup.options,"loading":true},on:{"loading":function($event){_setup.tableLoading = true},"loaded":function($event){_setup.tableLoading = false}},scopedSlots:_vm._u([{key:"id",fn:function(props){return _c('span',{},[_c('span',[_vm._v(_vm._s(props.index))])])}},{key:"Campaign",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'ShowPromoCode',
          params: { promoCodeId: props.row.id },
        }}},[_c('div',[_vm._v(_vm._s(props.row.description))])])],1)}},{key:"Customer",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'ShowPromoCode',
          params: { promoCodeId: props.row.id },
        }}},[(props.row.customer)?_c('div',[_vm._v(" "+_vm._s(props.row.customer.fname)+" "+_vm._s(props.row.customer.lname))]):_c('div',[_vm._v("-")])])],1)}},{key:"Promo Code",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'ShowPromoCode',
          params: { promoCodeId: props.row.id },
        }}},[_c('div',[_c('span',[_vm._v(_vm._s(props.row.promo_code))])])])],1)}},{key:"Max Number of Uses",fn:function(props){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'ShowPromoCode',
          params: { promoCodeId: props.row.id },
        }}},[_c('div',[_c('span',[_vm._v(_vm._s(props.row.max_number_of_uses))])])])],1)}},{key:"Expires At",fn:function(props){return _c('div',{},[_c('div',[_c('span',{class:props.row.expires_at ? 'text-primary' : 'text-secondary'},[_vm._v(_vm._s(props.row.expires_at ? (props.row.expires_at) : _setup.dateformat('Never')))])])])}},{key:"Status",fn:function(props){return _c('div',{},[_c('div',{staticClass:"badge",class:{ 'badge-success': !props.row.has_promotion_expired, 'badge-danger': props.row.has_promotion_expired }},[_vm._v(" "+_vm._s(!props.row.has_promotion_expired ? "Active" : "Inactive")+" ")])])}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }