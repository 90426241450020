<template>
  <div class="card m-4">
    <div class="card-body p-0">

      <v-server-table ref="companiesTable" :columns="columns" :options="options" :loading="true"
        @loading="tableLoading = true" @loaded="tableLoading = false">
        <span slot="id" slot-scope="props">
          <span>{{ props.index }}</span>
        </span>

        <div slot="Campaign" slot-scope="props">
          <router-link :to="{
            name: 'ShowPromoCode',
            params: { promoCodeId: props.row.id },
          }">
            <div>{{ props.row.description }}</div>
          </router-link>
        </div>

        <div slot="Customer" slot-scope="props">
          <router-link :to="{
            name: 'ShowPromoCode',
            params: { promoCodeId: props.row.id },
          }">
            <div v-if="props.row.customer"> {{ props.row.customer.fname }} {{ props.row.customer.lname }}</div>
            <div v-else>-</div>
          </router-link>
        </div>

        <div slot="Promo Code" slot-scope="props">
          <router-link :to="{
            name: 'ShowPromoCode',
            params: { promoCodeId: props.row.id },
          }">
            <div><span>{{ props.row.promo_code }}</span></div>
          </router-link>
        </div>

        <div slot="Max Number of Uses" slot-scope="props">
          <router-link :to="{
            name: 'ShowPromoCode',
            params: { promoCodeId: props.row.id },
          }">
            <div><span>{{ props.row.max_number_of_uses }}</span></div>
          </router-link>
        </div>

        <div slot="Expires At" slot-scope="props">
          <div>
            <span :class="props.row.expires_at ? 'text-primary' : 'text-secondary'">{{ props.row.expires_at ?
              (props.row.expires_at) :  dateformat('Never') }}</span>
          </div>
        </div>

        <div slot="Status" slot-scope="props">
          <div class="badge"
            :class="{ 'badge-success': !props.row.has_promotion_expired, 'badge-danger': props.row.has_promotion_expired }">
            {{ !props.row.has_promotion_expired
              ? "Active"
              : "Inactive"
            }}
          </div>
        </div>

      </v-server-table>
    </div>
  </div>
</template>

<script setup>
import promoCode from '@/api/promo_codes'
import moment from 'moment'
import {ref} from 'vue'


const dateformat = (v) => {
  if (v == 'Never') {
    return v
  }
  return moment(v, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm A')
}

const date = (value) => {
  if (!value) return ''
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return Intl.DateTimeFormat('en-US', options).format(value)
}


const tableLoading = ref(false)
const companies = ref(null)
const columns = ref(['id', 'Campaign', 'Customer', 'Promo Code', 'Max Number of Uses', 'Expires At', 'Status'])
const options = ref({
  loading: ref(true),
  filterable: ref(false),
  requestFunction: async (data) => {
    try {
      return await fetchReferralCodes(data)
    } catch (e) { }
  }
})




const fetchReferralCodes = async (params) => {
  tableLoading.value = true
  params.size = params.limit
  const result = await promoCode.listReferralCodes({ params })
  const newResult = { ...result.data, data: result.data.models, count: result.data.meta.total }
  tableLoading.value = false
  return {
    data: newResult
  }
}

</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
